import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router";
import {
    Flex,
    Button,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
} from "@chakra-ui/react";
import { TbPlus } from 'react-icons/tb';
import Error from '../components/Error';
import PageTitle from '../components/PageTitle';
import apiClient from '../services/api';

const CheckpointsList = () => {
    const [checkpoints, setCheckpoints] = useState([]);
    const [authError, setAuthError] = useState(false);
    const navigate = useNavigate();

    const fetchCheckpoints = async () => {
        await apiClient.get('/api/checkpoints')
            .then(response => {
                if(response.data.success) {
                    const payload = Object.values(response.data.payload);
                    setCheckpoints(payload);
                }
            }).catch(error => {
                console.error('Checkpoints error : ', error.response.data);
                setAuthError(error.response.data.message);
            });
    }
    
    // Call API to get the checkpoints
    useEffect(() => {
        fetchCheckpoints();
    }, []);

    const checkpointList = checkpoints.map((checkpoint, index) => 
        <Tr key={index}>
            <Td>{checkpoint.name}</Td>
            <Td>{checkpoint.nfc_id}</Td>
        </Tr>
    );

    return (
        <Flex
            id="mainContent"
            flexDirection="column"
            width="100%"            
            alignItems="stretch"
            alignSelf="stretch"
            justifyContent="space-between"
        >
            {authError && <Error title='Checkpoints error!' description={authError} /> }
            <PageTitle title="Checkpoints list"></PageTitle>
            {checkpoints?.length &&
                <Flex id="tableContent" alignSelf="stretch" w="100%" flexGrow="1" flexShrink="1" maxHeight="calc(100% - 64px)">
                    <TableContainer overflowY="auto" width="100%">
                        <Table variant="simple" colorScheme="blue" size="sm">
                            <Thead position="sticky" top={0}>
                                <Tr>
                                    <Th>Name</Th>
                                    <Th>NFC ID</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {checkpointList}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Flex>
            }

            <Button 
                leftIcon={<TbPlus />} 
                w="100%" 
                size="lg" 
                colorScheme="teal"
                onClick={() => navigate("/new")}
            >
                Add new checkpoint
            </Button>
        </Flex>
    );
};

export default CheckpointsList;