import React from 'react';
import {
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
} from "@chakra-ui/react";
import dayjs from 'dayjs';

export default function RoundCheckpoints({checkpoints}) {
    if (!checkpoints) return '';

    dayjs.locale('ro');

    const checkpointList = checkpoints.map((checkpoint, index) => 
        <Tr key={index}>
            <Td>{checkpoint.name}</Td>
            <Td>{ dayjs(checkpoint.created_at).format('DD MMM, HH:mm:ss') }</Td>            
        </Tr>
    );

    return (
        <TableContainer overflowY="auto" width="100%">
            <Table variant="simple" colorScheme="blue" size="sm">
                <Thead position="sticky" top={0}>
                    <Tr>
                        <Th>Checkpoint</Th>
                        <Th>Scanned</Th>                        
                    </Tr>
                </Thead>
                <Tbody>
                    { checkpointList }
                </Tbody>
            </Table>
        </TableContainer>
    );
}
