import React, { useState } from 'react';
import { useNavigate } from "react-router";
import {
    Flex,
    FormControl,
    Input,
    FormLabel,
    Button,
    HStack,
    Textarea
} from "@chakra-ui/react";
import Error from '../components/Error';
import PageTitle from '../components/PageTitle';
import apiClient from '../services/api';
import FileUpload from "../components/file-upload/file-upload.component";

const TicketAdd = () => {
    const [ message, setMessage ] = useState('');
    const [ title, setTitle ] = useState('');
    const [ authError, setAuthError] = useState(false);
    const navigate = useNavigate();

    const isTitleError = title === '';
    const isMessageError = message === '';

    const [newData, setNewData] = useState({
        profileImages: []
    });

    const updateUploadedFiles = (files) =>
        setNewData({ ...newData, profileImages: files });

    // Call API to add new ticket
    const addTicket = async (title, message, photos) => {
        const payload = {
            title : title,
            description : message,
            photos : photos
        };
        await apiClient.post('/api/ticket', payload, { headers: { 'Accept': 'application/json' } })
            .then(response => {
                if(response.data.success) {                    
                    navigate("../tickets");
                }
            }).catch(error => {
                setAuthError(error.response.data.message);
            });
    }

    const onSave = (e) => {
        e.preventDefault();
        addTicket(title, message, newData.profileImages);
        setMessage('');
    };

    return (
        <Flex
            id="mainContent"
            flexDirection="column"
            width="100%"            
            alignItems="stretch"
            alignSelf="stretch"
            justifyContent="space-between"
        >
            {authError && <Error title='Tickets error!' description={authError} />}
            <PageTitle title="Add new ticket"></PageTitle>
            <Flex id="tableContent" alignSelf="stretch" w="100%" flexGrow="1" flexDirection="column">
                <form onSubmit={() => onSave} width="100%">
                    <FormControl isInvalid={isTitleError} isRequired>
                        <FormLabel>Title</FormLabel>
                        <Input 
                            type="text" 
                            placeholder="eg. something broke" 
                            value={title} 
                            onChange={(e) => setTitle(e.target.value)}
                            />
                    </FormControl>
                    <FormControl isInvalid={isMessageError} isRequired>
                        <FormLabel>Problem</FormLabel>
                        <Textarea                         
                            placeholder="Describe the problem as detailed as possible" 
                            value={message} 
                            size="lg"
                            onChange={(e) => setMessage(e.target.value)}
                            />
                    </FormControl>
                    <FormControl>
                        <FormLabel>Add images</FormLabel>
                        <FileUpload
                            accept=".jpg,.png,.jpeg"                            
                            multiple
                            id="photos"
                            updateFilesCb={updateUploadedFiles}
                        />
                    </FormControl>
                </form>                
            </Flex>
            <HStack>
                <Button 
                    w="100%" 
                    size="lg" 
                    colorScheme="teal" 
                    variant="solid" 
                    type="submit"
                >
                    Save
                </Button>
                <Button 
                    w="100%" 
                    size="lg"
                    colorScheme="teal" 
                    variant='outline' 
                    onClick={() => navigate("/tickets")}
                >
                    Cancel
                </Button>
            </HStack>
        </Flex>
    );
};

export default TicketAdd;
