import {
    Flex,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
} from "@chakra-ui/react";

const Error = ({ title, description }) => {
    return(
        <Alert status="error" mb="24px;" flexDir="column" alignItems="flex-start" minHeight="144px">
            <Flex>
                <AlertIcon />
                <AlertTitle>{title}</AlertTitle>
            </Flex>
            <AlertDescription>{description}</AlertDescription>
        </Alert>
    );
}

export default Error;