import React, { createContext, useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import apiClient from "../services/api";

export const UserContext = createContext({
  user: null,
  roles: null,
  error: null,
  setUser: () => {},
  setRoles: () => {},
  setError: () => {},
  checkRole: () => {}
});

export const UserProvider = ({ children }) => {
  const [ user, setUser ] = useState(null);  
  const [ roles, setRoles ] = useState(null);
  const [ error, setError ] = useState(null);

  // Check user role
  const checkRole = (routeRoles) => {
    let found = false;

    if(!routeRoles.length) {
      found = true;
    } else {
      found = roles?.some(r => r && routeRoles.includes(r))
    }
    return found;
  }

  async function getUserRoles() {
    await apiClient.get('/api/role')
        .then(response => {
            if(response.data.success) {
                const payload = Object.values(response.data.payload);
                const roles = payload[0].map( (role) => { return role.name });
                setRoles(roles);                    
            }
        }
    ).catch(error => {
        console.error(error.response.data);
        setError(error.response.data.message);
    });
}

  useEffect(() => {
    const cookies = new Cookies();

    // Check if the encoded cookie exists
    if(cookies.get('uid')) {
      // Decode the values from it
      const userData = atob(cookies.get('uid')).split('|');

      // Set the current user data
      setUser({
        email: userData[0],                            
        name: userData[1],
        token: cookies.token,
        user_id: userData[2] 
      });

      // Get the roles
      getUserRoles();
    } else {
      setUser(null);
      setRoles(null);
      setError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [children]);

  const value = { user, setUser, roles, setRoles, error, setError, checkRole, getUserRoles };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}
