import React, { useContext} from "react";
import { Outlet, Link } from 'react-router-dom';
import { Flex, Center, IconButton } from "@chakra-ui/react";
import { TbDoorExit } from 'react-icons/tb';
import { useNavigate  } from "react-router";
import { UserContext } from "../context/user.context";
import Cookies from 'universal-cookie';
import apiClient from '../services/api';
import NavigationLinks from "../components/NavigationLinks";

export default function MainContainer () {
  const { user, setUser, setRoles } = useContext(UserContext);
  const navigate = useNavigate();

  // Logout function
  const logout = async () => {
    const cookies = new Cookies();

    await apiClient.post('/api/logout').then(response => {
      cookies.remove('token');
      cookies.remove('uid');
      setUser(null);
      setRoles(null);
      navigate("/login");
    }).catch(error => {
      console.error('Logout error : ', error.response.data);
    })
  }

  return (
    <Flex id="navigation" direction="column" width="100%" height="100vh" justifyContent="stretch" backgroundColor="gray.200">
      { user ?  
        <Flex id="title" p="16px" width="100%" flexDirection="row" justifyContent="space-between" alignItems="center">
          <div>Logged in as <strong>{user.name}</strong></div>
          <IconButton colorScheme="orange" size="lg" aria-label="Logout" icon={<TbDoorExit/>} onClick={() => logout()}></IconButton>
        </Flex>
      :
        <Center p="16px" h="80px">
          <Link to='/login'>Click here to login</Link>
        </Center>
      }
      <Flex id="content" width="100%" flexGrow="1" p="16px" flexDirection="col" maxHeight="calc(100% - 164px)" justifyContent="stretch" backgroundColor="white">
        <Outlet />
      </Flex>
      <Flex id="footer" p="16px" w="100%" flexGrow="0" flexShrink="0" flexDirection="row" justifyContent="space-between">
        <NavigationLinks location="bottom"/>        
      </Flex>
    </Flex>
  );
}
