import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const useCookies = cookies.get('token');

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
        'Content-Type'  : 'application/json',
        'Authorization' : (useCookies ?  'Bearer ' + cookies.get('token') : '')
    }
});

export default apiClient;