import React from "react";
import LogIn from '../pages/LogIn';
import Dashboard from '../pages/Dashboard';
import CheckpointsList from '../pages/CheckpointList';
import CheckpointScan from '../pages/CheckpointScan';
import CheckpointAdd from '../pages/CheckpointAdd';
import Rounds from '../pages/Rounds';
import Round from '../pages/Round';
import TicketList from '../pages/TicketList';
import TicketAdd from '../pages/TicketAdd';
import IncidentList from '../pages/IncidentList';
import NotFound from '../pages/NotFound';

import { TbAlertTriangle, TbAlertOctagon, TbAspectRatio, TbWalk, TbChartDots3, TbBarcode, TbDoorEnter, TbLayoutDashboard } from 'react-icons/tb';

const routes = [
  {
    path: '/',
    title: 'Dashboard',
    component: <Dashboard/>,
    icon: <TbLayoutDashboard  />,
    showInMenu: true,
    allowedRoles: []
  },
  {
    path: '/login',
    title: 'Login',
    component: <LogIn/>,
    icon: <TbDoorEnter />,
    showInMenu: false,
    allowedRoles: []
  },
  {
    path: '/scan',
    title: 'Checkpoint scan',
    component: <CheckpointScan/>,
    icon: <TbBarcode />,
    showInMenu: true,
    allowedRoles: ['manage-checkpoints']
  },
  {
    path: '/checkpoints',
    title: 'Checkpoint list',
    component: <CheckpointsList/>,
    icon: <TbAspectRatio />,
    showInMenu: true,
    allowedRoles: ['manage-checkpoints']
  },  
  {
    path: '/new',
    title: 'Add checkpoint',
    component: <CheckpointAdd/>,
    icon: <TbWalk />,
    showInMenu: false,
    allowedRoles: ['manage-checkpoints']
  },
  {
    path: '/round',
    title: 'Round details',
    component: <Round/>,
    icon: <TbChartDots3 />,
    showInMenu: true,
    allowedRoles: ['manage-rounds']
  },    
  {
    path: '/rounds',
    title: 'Rounds',
    component: <Rounds/>,
    icon: <TbWalk />,
    showInMenu: true,
    allowedRoles: ['manage-rounds']
  },
  {
    path: '/tickets',
    title: 'Tickets',
    component: <TicketList/>,
    icon: <TbAlertOctagon />,
    showInMenu: true,
    allowedRoles: ['manage-tickets']
  },
  {
    path: '/add-ticket',
    title: 'Add Ticket',
    component: <TicketAdd/>,
    icon: <TbAlertOctagon />,
    showInMenu: false,
    allowedRoles: ['manage-tickets']
  },
  {
    path: '/incidents',
    title: 'Incidents',
    component: <IncidentList/>,
    icon: <TbAlertTriangle />,
    showInMenu: true,
    allowedRoles: ['manage-incidents']
  }, 
  {
    path: '/*',
    title: '404',
    component: <NotFound/>,
    icon: <TbAlertTriangle />,
    showInMenu: false,
    allowedRoles: []
  },
]
   
export default routes;
