
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react'

import App from './App';
import { UserProvider } from './context/user.context';
import { RoundProvider } from './context/round.context';

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <ChakraProvider>
            <BrowserRouter>
                <UserProvider>
                    <RoundProvider>
                        <App />
                    </RoundProvider>
                </UserProvider>
            </BrowserRouter>
        </ChakraProvider>
    </React.StrictMode>
);
