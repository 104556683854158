import React, { useState } from 'react';
import {
    Flex,
    Button,
    Table,
    TableContainer,
    Tbody,
    Tr,
    Td    
} from "@chakra-ui/react";
import { TbScan } from 'react-icons/tb';
import { useGeolocated } from "react-geolocated";
import { ActionsContext } from '../context/scan.context';
import PageTitle from '../components/PageTitle';
import Scan from '../containers/Scan';

const CheckpointScan = () => {

    // Geolocation stuff
    const { coords, isGeolocationAvailable, isGeolocationEnabled } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        userDecisionTimeout: 5000,
    });

    // Scan functions
    const [actions, setActions] = useState(null);
    const {status} = actions || {};
    const actionsValue = {actions, setActions};

    const startScan = () => setActions(
        {
            status: 'scanning'            
        }
    );

    return !isGeolocationAvailable ? (
        <div>Your browser does not support Geolocation</div>
    ) : !isGeolocationEnabled ? (
        <div>Geolocation is not enabled</div>
    ) : coords ? (
        <Flex
            flexDirection="column"
            width="100%"
            height="100%"
            justifyContent="flex-start"
        >
            <PageTitle title="Scan checkpoint"></PageTitle>
            <Flex id="tableContent" alignSelf="stretch" w="100%" flexGrow="1">
                <TableContainer overflowY="scroll" width="100%">
                    <Table variant="simple" colorScheme="blue" size="sm" position='relative'>
                        <Tbody>
                            <Tr>
                                <Td>Latitude</Td>
                                <Td>{coords.latitude}</Td>
                            </Tr>
                            <Tr>
                                <Td>Longitude</Td>
                                <Td>{coords.longitude}</Td>
                            </Tr>
                            <Tr>
                                <Td>Altitude</Td>
                                <Td>{coords.altitude}</Td>
                            </Tr>
                        </Tbody>                    
                    </Table>
                </TableContainer>
            </Flex>
            <ActionsContext.Provider value={actionsValue}>
            {status ? 
                <Scan location={coords}/> 
                :
                <Button 
                    leftIcon={<TbScan />} 
                    alignSelf="flex-end"
                    w="100%" 
                    size="lg" 
                    colorScheme="teal"
                    onClick={startScan}
                >
                    Scan
                </Button>
            }
            </ActionsContext.Provider>
        </Flex>
    ) : (
      <div>Getting the location data&hellip; </div>
    );

};

export default CheckpointScan;
