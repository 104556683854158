import React, { useState } from 'react';
import { useNavigate } from "react-router";
import { Flex, Button } from "@chakra-ui/react";
import { ActionsContext } from '../context/scan.context';
import Scanner from '../components/Scanner';
import Writer from '../components/Writer';
import Error from '../components/Error';
import apiClient from '../services/api';

const CheckpointAdd = () => {

    // Scan functions
    const [ actions, setActions ] = useState({         
        status: 'writing' 
    });
    const actionsValue = { actions, setActions };    
    const [ writeError, setWriteError] = useState();
    const navigate = useNavigate();

    // Call API to add new chekpoint
    const addCheckpoint = async(name, nfc_id) => {    
        const payload = {
            name,
            nfc_id
        };

        await apiClient.post("/api/checkpoint", payload, { headers: { 'Accept': 'application/json' } })
            .then(response => {
                if(response.data.success) {                    
                    //setWriteError("Tag saved successfully!");
                }
            }).catch(error => {                
                setWriteError(error.response.data.message);
            });
    }

    const afterSaveCallback = async(message) => {
        setActions({                
            status: "waiting"
        });

        // If we are using on a computer, catch the error
        if ('NDEFReader' in window) { 
            const ndef = new window.NDEFReader();

            let ignoreRead = false;

            ndef.onreading = ({ message, serialNumber }) => {
                if (ignoreRead) {
                    return; // write pending, ignore read.
                }

                let decodedMessage = "";

                for (const record of message.records) {
                    switch (record.recordType) {
                        case "text":
                            const textDecoder = new TextDecoder(record.encoding);
                            decodedMessage = textDecoder.decode(record.data);
                            break;
                        case "url":
                            // TODO: Read URL record with record data.
                            break;
                        default:
                            // TODO: Handle other records with record data.
                    }
                }

                addCheckpoint(decodedMessage, serialNumber);

                setActions({
                    status: "write_ok"
                });

                console.log("Tag updated!");
                setWriteError("Tag updated!");
            };

            function write(data) {
                ignoreRead = true;

                return new Promise((resolve, reject) => {
                    ndef.addEventListener("reading", event => {
                        // Check if we want to write to this tag, or reject.
                        ndef.write(data).then(resolve, reject).finally(() => ignoreRead = false);
                    }, { once: true });
                });
            }

            // This line will avoid showing the native NFC UI reader
            await ndef.scan();

            try {
                await write({
                    records: [{ 
                        recordType: "text", 
                        data: message
                    }]
                });
            } catch(err) {
                console.error("Something went wrong", err);
                setWriteError("Cannot write data to the NFC tag. Try another one?");
            }
        } else {
            addCheckpoint(message, "nfc_reader_not_present");

            setActions({                
                status: "write_ok"
            });

            console.error("NFC reader not present");
            setWriteError("NFC reader not present");
        }
    } 

    return (
        <Flex
            flexDirection="column"
            width="100%"
            height="100%"
            justifyContent="flex-start"
            alignItems="flex-start"
            gap="16px"
        >
            <ActionsContext.Provider value={actionsValue}>
            <>
                { writeError && <Error title="Write error!" description={writeError} /> }
                { actions.status === "write_ok" ?
                <Flex w="100%" justifyContent="center">
                    <Button 
                        w="100%" 
                        size="lg" 
                        colorScheme="teal" 
                        variant="outline" 
                        onClick={() => navigate("/checkpoints")}
                    >
                        Back to list
                    </Button> 
                </Flex> : (actions.status === "waiting" ? <Scanner status={actions.write} /> : <Writer onSaveCallback={afterSaveCallback}/> )
                }
            </>
            </ActionsContext.Provider>            
        </Flex>
    );
};

export default CheckpointAdd;
