import { useState, useEffect, createContext } from "react";
import Cookies from 'universal-cookie';

export const RoundContext = createContext({
  round: {
    id: null,
    checkpoint: null
  },
  setRound: () => {}
});

export const RoundProvider = ({ children }) => {
  const [round, setRound] = useState(null);  
  const value = { round, setRound };

  useEffect(() => {
    const cookies = new Cookies();
    
    // If cookie is present then the round started
    if(cookies.get('rid') || cookies.get('cid')) {    
      // Set the current round data
      setRound({
        id: cookies.get('rid'),
        checkpoint: cookies.get('cid'),
      });      
    } else {
      setRound({
        id: null,
        checkpoint: null
      });
    }
  }, []);

  return <RoundContext.Provider value={value}>{children}</RoundContext.Provider>;
}
