import React, { useState } from 'react';
import { useNavigate } from "react-router";
import {
    Flex,
    Stack,
    FormControl,
    Input,
    FormLabel,
    Button,
    HStack,
} from "@chakra-ui/react";

const Writer = ({onSaveCallback}) => {
    const [ checkpointName, setCheckpointName ] = useState('');
    const navigate = useNavigate();
    const isError = checkpointName === '';

    const onSave = (e) => {
        e.preventDefault();
        setCheckpointName('');
        onSaveCallback(checkpointName);        
    };

    return (
      <Flex w="100%" justifyContent="center">
        <form width="100%">
            <Stack
                spacing={4}
                p="1rem"
                w="100%"
            >
                <FormControl isInvalid={isError} isRequired>
                    <FormLabel>Checkpoint name</FormLabel>
                    <Input 
                        type="text" 
                        placeholder="Checkpoint name" 
                        value={checkpointName} 
                        onChange={(e) => setCheckpointName(e.target.value)}
                        />                    
                </FormControl>
                <HStack>
                    <Button 
                        w="100%" 
                        size="lg" 
                        colorScheme="teal" 
                        variant="solid" 
                        type="submit"
                        onClick={(e) => onSave(e)}
                    >
                        Save
                    </Button>
                    <Button 
                        w="100%" 
                        size="lg" 
                        colorScheme="teal" 
                        variant="outline" 
                        onClick={() => navigate("/checkpoints")}
                    >
                        Cancel
                    </Button>
                </HStack>                
            </Stack>
        </form>
      </Flex>
    );
};

export default Writer;
