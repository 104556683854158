import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router";
import dayjs from 'dayjs';
import {
    Flex,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Button
} from "@chakra-ui/react";
import { MdReportProblem } from 'react-icons/md';
import Error from '../components/Error';
import PageTitle from '../components/PageTitle';
import apiClient from '../services/api';

const IncidentList = () => {
    const [ incidents, setIncidents ] = useState([]);
    const [ authError, setAuthError] = useState(false);
    const navigate = useNavigate();

    const fetchIncidents = async () => {
        await apiClient.get('/api/incidents')
            .then(response => {
                if(response.data.success) {
                    const payload = Object.values(response.data.payload);
                    setIncidents(payload);
                }
            }).catch(error => {
                setAuthError(error.response.data.message);
            });
    }

    // Call API to get the incidents
    useEffect(() => {
        fetchIncidents()
    }, []);

    dayjs.locale('ro');

    const incidentsList = (incidents) => {        
        return incidents.map((incident, index) => 
            <Tr key={index} bgColor="white">
                <Td>
                    {dayjs(incident.created_at).format('DD MMM, HH:mm:ss')}                    
                </Td>
                <Td>
                    {incident.location}
                </Td>
            </Tr>
        );
    }

    return (
        <Flex
            id="mainContent"
            flexDirection="column"
            width="100%"            
            alignItems="stretch"
            alignSelf="stretch"
            justifyContent="space-between"
        >
            { authError && <Error title='Incidents error!' description={authError} /> }
            <PageTitle title="Incidents list"></PageTitle>
            {incidents?.length ?
                <Flex id="tableContent" alignSelf="stretch" w="100%" flexGrow="1">
                    <TableContainer overflowY="scroll" width="100%">
                        <Table variant="simple" colorScheme="blue" size="sm" position='relative'>
                            <Thead position="sticky" top={0} bgColor="#fff">
                                <Tr>
                                    <Th>Date / Time</Th>                            
                                    <Th>Title</Th>                            
                                </Tr>
                            </Thead>
                            <Tbody>
                                {incidentsList(incidents)}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Flex>
            : null }

            <Button 
                leftIcon={<MdReportProblem />} 
                alignSelf="flex-end"
                w="100%" 
                size="lg" 
                colorScheme="teal"
                onClick={() => navigate("/add-incident")}
            >
                Add new incident
            </Button>
        </Flex>
    );
};

export default IncidentList;
