import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router";
import dayjs from 'dayjs';
import {
    Flex,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    Button
} from "@chakra-ui/react";
import { MdReportProblem } from 'react-icons/md';
import Error from '../components/Error';
import PageTitle from '../components/PageTitle';
import apiClient from '../services/api';

const TicketList = () => {
    const [ tickets, setTickets ] = useState([]);
    const [ authError, setAuthError] = useState(false);
    const navigate = useNavigate();

    const fetchTickets = async () => {
        await apiClient.get('/api/tickets')
            .then(response => {
                if(response.data.success) {
                    const payload = Object.values(response.data.payload);
                    setTickets(payload);
                }
            }).catch(error => {
                setAuthError(error.response.data.message);
            });
    }

    // Call API to get the tickets
    useEffect(() => {
        fetchTickets()
    }, []);

    dayjs.locale('ro');

    const ticketsList = (tickets) => {        
        return tickets.map((ticket, index) => 
            <Tr key={index} bgColor="white">
                <Td>
                    {dayjs(ticket.created_at).format('DD MMM, HH:mm:ss')}                    
                </Td>
                <Td>
                    {ticket.description}
                </Td>
            </Tr>
        );
    }

    return (
        <Flex
            id="mainContent"
            flexDirection="column"
            width="100%"            
            alignItems="stretch"
            alignSelf="stretch"
            justifyContent="space-between"
        >
            {authError && <Error title='Tickets error!' description={authError} />}
            <PageTitle title="Tickets list"></PageTitle>
            {tickets?.length &&
                <Flex id="tableContent" alignSelf="stretch" w="100%" flexGrow="1">
                    <TableContainer overflowY="scroll" width="100%">
                        <Table variant="simple" colorScheme="blue" size="sm" position='relative'>
                            <Thead position="sticky" top={0} bgColor="#fff">
                                <Tr>
                                    <Th>Date / Time</Th>                            
                                    <Th>Title</Th>                            
                                </Tr>
                            </Thead>
                            <Tbody>
                                {ticketsList(tickets)}
                            </Tbody>
                        </Table>
                    </TableContainer>
                </Flex>
            }

            <Button 
                leftIcon={<MdReportProblem />} 
                alignSelf="flex-end"
                w="100%" 
                size="lg" 
                colorScheme="teal"
                onClick={() => navigate("../add-ticket")}
            >
                Add new ticket
            </Button>
        </Flex>
    );
};

export default TicketList;
