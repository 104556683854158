import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router";
import {
    Flex,
    Button,
} from "@chakra-ui/react";
import { TbPlus, TbCheck, TbRepeat } from 'react-icons/tb';
import { RoundContext } from '../context/round.context';
import RoundCheckpoints from '../components/RoundCheckpoints';
import Error from '../components/Error';
import PageTitle from '../components/PageTitle';
import apiClient from '../services/api';

const Round = () => {
    const [ checkpoints, setCheckpoints ] = useState([]);
    const [ authError, setAuthError ] = useState(false);
    const { round } = useContext(RoundContext);
    const navigate = useNavigate();

    useEffect(() => {
        // Call API to get the checkpoints
        const fetchCheckpoints = async () => {
            await apiClient.get('/api/round/' + round.id + '/checkpoints')
                .then(response => {    
                    if(response.data.success) {
                        const payload = Object.values(response.data.payload);
                        setCheckpoints(payload[0]);
                    }
                }).catch(error => {
                    console.error('Checkpoints error : ', error.response.data);
                    setAuthError(error.response.data.message);
                });
        }

        // Only fetch the checkpoints if we have an active round or if the round is valid
        if (round && round?.id) {
            fetchCheckpoints();
        } else {
            setAuthError('No active round. Please start or continue one.');
        }        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Flex
            id="mainContent"
            flexDirection="column"
            width="100%"            
            alignItems="stretch"
            alignSelf="stretch"
            justifyContent="space-between"
        >
            {authError && <Error title='Round error!' description={authError} />}
            {checkpoints ?
            <>
                <Flex id="tableContent" alignSelf="stretch" w="100%" flexDirection="column" flexGrow="1" flexShrink="1" maxHeight="calc(100% - 128px)">                    
                    <PageTitle title="Round checkpoints list"></PageTitle>
                    {checkpoints?.length ? 
                    <RoundCheckpoints checkpoints={checkpoints} />
                    : null }
                </Flex>
                <Flex flexDirection="column" width="100%" gap={2}>
                    <Button 
                        leftIcon={<TbPlus />} 
                        w="100%" 
                        size="lg" 
                        colorScheme="teal"
                        onClick={() => navigate("/scan")}
                    >
                        Scan checkpoint
                    </Button>
                    <Button 
                        leftIcon={<TbCheck />} 
                        w="100%" 
                        size="lg"
                        variant="outline" 
                        colorScheme="teal"
                        onClick={() => navigate("/")}
                    >
                        End round
                    </Button>
                </Flex>
            </> 
            : 
            <Button 
                leftIcon={<TbRepeat />} 
                w="100%" 
                size="lg" 
                colorScheme="teal"
                onClick={() => navigate("/")}
            >
                Start new round
            </Button>
            }
        </Flex>

    );
};

export default Round;
