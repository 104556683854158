import React, { useContext } from 'react';
import '../components/Scanner.css'
import Spinner from '../spinner.gif';
import { ActionsContext } from '../context/scan.context';

const Scanner = () => {
  const { setActions } = useContext(ActionsContext);
  
  return (
    <div className="scanner">
      <p className="scanner-exit" onClick={() => setActions({status: null})}>X</p>
      <div className="scanner-container">
        <img src={Spinner} alt="Please wait..." className="scanner-image"/>
        <p className="scanner-text">
          Scanning...
        </p>
      </div>
    </div>
  );
};

export default Scanner;