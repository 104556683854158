import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router";
import dayjs from 'dayjs';
import {
    Flex,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableContainer,
    IconButton,
    Button
} from "@chakra-ui/react";
import { TbCheck, TbRepeat, TbRefresh } from 'react-icons/tb';
import { RoundContext } from '../context/round.context';
import Error from '../components/Error';
import PageTitle from '../components/PageTitle';
import Cookies from 'universal-cookie';
import apiClient from '../services/api';

const Rounds = () => {
    const [ rounds, setRounds ] = useState([]);
    const [ authError, setAuthError] = useState(false);
    const { round, setRound } = useContext(RoundContext);
    const cookies = new Cookies();
    const navigate = useNavigate();

    const fetchRounds = async () => {
        await apiClient.get('/api/rounds')
            .then(response => {
                if(response.data.success) {
                    const payload = Object.values(response.data.payload);
                    setRounds(payload);
                }
            }).catch(error => {
                setAuthError(error.response.data.message);
            });
    }

    // Call API to get the rounds
    useEffect(() => {
        fetchRounds()
    }, []);

    // Call API to add new rounds
    const addNewRound = async () => {        
        await apiClient.post('/api/round')
            .then(response => {
                if(response.data.success) {
                    // Set the round cookie and context
                    cookies.set('rid', response.data.payload.round_id);
                    setRound({
                        id : response.data.payload.round_id                    
                    });
                    fetchRounds();
                }
            }).catch(error => {
                setAuthError(error.response.data.message);
            });
    }

    // Continue a started round
    const continueRound = (round_id) => {
        cookies.set('rid', round_id);
        setRound({
            id : round_id                   
        });
        navigate("../round");
    }

    // Call API to complete rounds
    const completeRound = async (round_id) => {        
        await apiClient.get('/api/round/' + round_id + '/complete')
            .then(response => {
                if(response.data.success) {
                    // Clear the round cookie and context
                    cookies.set('rid', null);

                    setRound({
                        id : null
                    });
                    fetchRounds();
                }
            }).catch(error => {
                setAuthError(error.response.data.message);
            });
    }

    dayjs.locale('ro');

    const roundsList = (rounds) => {
        const currentRound = parseInt(round?.id);
        
        return rounds.map( (round, index) => 
            <Tr key={index} bgColor={round.id === currentRound ? "gray.50" : "white"}>
                <Td>
                    {dayjs(round.created_at).format('DD MMM, HH:mm:ss')} <br/>
                    { round.ended_at ? dayjs(round.ended_at).format("DD MMM, HH:mm:ss") : "-" }
                </Td>
                <Td w="64px">
                    {!round.ended_at && (round.id === currentRound ? "Active" : <IconButton colorScheme="gray" aria-label="Continue round" icon={<TbRefresh/>} onClick={() => continueRound(round.id)}></IconButton>)}
                </Td>
                <Td w="64px">
                    {!round.ended_at && <IconButton colorScheme="gray" aria-label="End round" icon={<TbCheck/>} onClick={() => completeRound(round.id)}></IconButton>}
                </Td>
            </Tr>
        );
    }

    return (
        <Flex
            id="mainContent"
            flexDirection="column"
            width="100%"            
            alignItems="stretch"
            alignSelf="stretch"
            justifyContent="space-between"
        >
            { authError && <Error title='Rounds error!' description={authError} /> }
            <Flex id="tableContent" alignSelf="stretch" flexDirection="column" w="100%" flexGrow="1" flexShrink="1" maxHeight="calc(100% - 64px)">
                <PageTitle title="Rounds list"></PageTitle>
                <TableContainer overflowY="scroll" width="100%">
                    <Table variant="simple" colorScheme="blue" size="sm" position='relative'>
                        <Thead position="sticky" top={0} bgColor="#fff">
                            <Tr>
                                <Th>Start / End</Th>                            
                                <Th></Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {roundsList(rounds)}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Flex>

            <Button 
                leftIcon={<TbRepeat />} 
                w="100%" 
                size="lg"
                colorScheme="teal"
                onClick={() => addNewRound()}
            >
                Start new round
            </Button>
        </Flex>
    );
};

export default Rounds;
