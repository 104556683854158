import React, { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router";
import {
    Flex,
    Input,
    Button,
    InputGroup,
    Stack,
    InputLeftElement,
    chakra,
    Box,
    FormControl,
    InputRightElement,
} from "@chakra-ui/react";
import Error from "../components/Error";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { ReactComponent as Logo } from "../nfc.svg";
  
import Cookies from "universal-cookie";
import apiClient from "../services/api";
import { UserContext } from "../context/user.context";

export default function LogIn () {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState("");
    const { setUser, getUserRoles } = useContext(UserContext);
    const [authError, setAuthError] = useState();
    const navigate = useNavigate();
    const buttonRef = useRef(null);

    const CFaUserAlt = chakra(FaUserAlt);
    const CFaLock = chakra(FaLock);

    function handleSubmit (event) {
        event.preventDefault();
        buttonRef.current.disabled = true;

        apiClient.get('/sanctum/csrf-cookie')
            .then(response => {
                const payload = {
                    email,
                    password
                };

                apiClient.post('/api/login', payload, {headers: { 'Accept': 'application/json' } })
                    .then( ({data}) => {
                        if(!data.payload.token) {
                            setAuthError('Auth error: Token is missing from the request.');
                            return;
                        }

                        if(!data.payload.uid) {
                            setAuthError('Auth error: Uid is missing from the request.');
                            return;
                        }

                        // Decode the data
                        const userData = atob(data.payload.uid).split('|');

                        // Set the current user data
                        setUser({
                            email: userData[0],                            
                            name: userData[1],
                            token: data.payload.token,
                            user_id: userData[2] 
                        });
                        
                        // Set the current user roles
                        getUserRoles(); 

                        const cookies = new Cookies();
                        cookies.set('token', data.payload.token, { path: '/' });                       
                        cookies.set('uid', data.payload.uid, { path: '/' });

                        navigate("/");
                    }).catch(error => {
                        console.error(error.response.data);
                        setAuthError(error.response.data.message);
                        buttonRef.current.disabled = false;
                    });
            }).catch(error => {
                console.error(error.response.data);
                setAuthError(error.response.data.message);
                buttonRef.current.disabled = false;
            });
    }

    const [showPassword, setShowPassword] = useState(false);
    const handleShowClick = () => setShowPassword(!showPassword);

    return (

        <Flex
            id="mainContent"
            flexDirection="column"
            width="100%"            
            alignItems="center"
            alignSelf="stretch"
            justifyContent="center"
        >
            { authError && <Error title='Authentication error!' description={authError} /> }

            <Box minW="90%" justifyContent="center">
                <form onSubmit={handleSubmit} width="100%">
                    <Stack
                        spacing={4}
                        p="1rem"
                        alignItems="center"
                    >
                        <Logo />

                        <FormControl>
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    children={<CFaUserAlt color="gray.300" />}
                                />
                                <Input 
                                    type="email" 
                                    placeholder="Email address" 
                                    value={email} 
                                    onChange={(e) => setEmail(e.target.value)} 
                                />
                            </InputGroup>
                        </FormControl>

                        <FormControl>
                            <InputGroup>
                                <InputLeftElement
                                    pointerEvents="none"
                                    color="gray.300"
                                    children={<CFaLock color="gray.300" />}
                                />
                                <Input
                                    type={showPassword ? "text" : "password"}
                                    placeholder="Password"
                                    required 
                                    value={password} 
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <InputRightElement width="4.5rem">
                                    <Button h="1.75rem" size="sm" onClick={handleShowClick}>
                                    {showPassword ? "Hide" : "Show"}
                                    </Button>
                                </InputRightElement>
                            </InputGroup>

                        </FormControl>

                        <Button
                            type="submit"
                            variant="solid"
                            colorScheme="teal"
                            width="full"
                            ref={buttonRef}
                        >
                            Login
                        </Button>
                    </Stack>
                </form>
            </Box>
        </Flex>
    )
}