import React from "react";
import { Flex } from "@chakra-ui/react";
import NavigationLinks from "../components/NavigationLinks";

const Dashboard = () => {
    return (
        <Flex 
            flexDirection="column" 
            alignItems="center"
            width="100%"
            height="100%">

            <Flex
                flexWrap="wrap"
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="flex-start"
                alignContent="center"
                gap="16px"
            >
                <NavigationLinks location="dashboard"/>
            </Flex>
        </Flex>
    );
};

export default Dashboard;
