import React, { useContext, useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { Flex, Text, IconButton } from "@chakra-ui/react";
import { UserContext } from "../context/user.context";
import routes from '../config/routes.js';

const NavigationLinks = ({ location }) => {
    const { user, checkRole } = useContext(UserContext);
    const [userIsActive, setUserIsActive ] = useState(user ? true : false);

    useEffect(() => {
        setUserIsActive(user);
    }, [user]);

    // Based on the position prop we render different layouts for the navigation links
    return(
        userIsActive && routes.map((route, index) => 
            (route.showInMenu && checkRole(route.allowedRoles)) && (
                location === "bottom" ? (
                    <IconButton 
                        colorScheme="blue" 
                        size="lg"                 
                        _focus={{
                        boxShadow:
                            '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                        }}
                        _hover={{ bg: '#ebedf0', borderColor: '#999' }}
                        _active={{
                        bg: '#dddfe2',
                        transform: 'scale(0.98)',
                        borderColor: '#999',
                        }}
                        as={Link} 
                        to={route.path} 
                        aria-label={route.title} 
                        key={index} 
                        icon={route.icon}>
                    </IconButton>
                ) : (
                    <Flex flexDirection="column" alignItems="center" gap="8px" key={index}>
                        <IconButton 
                            height="100px"
                            width="100px"
                            fontSize="6xl"
                            colorScheme="teal"
                            _focus={{
                                boxShadow:
                                '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
                            }}
                            _hover={{ bg: '#ebedf0' }}
                            _active={{
                                bg: '#dddfe2',
                                transform: 'scale(0.98)',
                                borderColor: '#bec3c9',
                            }}
                            as={Link} 
                            to={route.path} 
                            aria-label={route.title}                            
                            icon={route.icon}>
                        </IconButton>
                        <Text fontSize="xs">{route.title}</Text>
                    </Flex>
                )            
            )
        )
    );    
}

export default NavigationLinks;