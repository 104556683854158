import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ActionsContext } from '../context/scan.context';
import { RoundContext } from '../context/round.context';
import { UserContext } from "../context/user.context";
import Cookies from 'universal-cookie';
import Scanner from '../components/Scanner';
import Error from '../components/Error';
import apiClient from '../services/api';

const Scan = ({ location }) => {
    const [ message, setMessage ] = useState('');
    const [ serialNumber, setSerialNumber ] = useState('');
    const { actions, setActions } = useContext(ActionsContext);
    const { round, setRound } = useContext(RoundContext);
    const { user } = useContext(UserContext);
    const [ scanError, setScanError] = useState();
    const cookies = new Cookies();

    const latitude = location.latitude;
    const longitude = location.longitude;

    // Call API to add checkpoint to current round
    const addCheckpointToRound = async () => {
        //alert("Uid:" + user.user_id + ", rid:" + round.id + " cid:" + round.checkpoint + " cookie:" + cookies.get("cid"));
        //alert("Chk id :" + round.checkpoint)

        const payload = {
            round_id : round.id,
            user_id : user.user_id,
            gps : (latitude + "," + longitude),
            nfc_id : round.checkpoint
        };

        await apiClient.post('/api/checkpoint/add', payload, { headers: { 'Accept': 'application/json' } })
            .then(response => {
                if(response.data.success) {
                    setScanError("Scan successfully!");
                }
            }).catch(error => {
                setScanError(error.response.data.message);
            });
    }

    const scan = useCallback(async() => {
        if ('NDEFReader' in window) { 
            try {
                const ndef = new window.NDEFReader();
                await ndef.scan();
                
                ndef.onreadingerror = () => {
                    setScanError("Cannot read data from the NFC tag. Try another one?");
                };
                
                ndef.onreading = event => {
                    onReading(event);
                };
            } catch(error){
                setScanError(`Scan failed to start: ${error}.`);
            };
        }
        // eslint-disable-next-line
    }, [setActions]);

    const onReading = ({ message, serialNumber }) => {
        for (const record of message.records) {
            switch (record.recordType) {
                case "text":
                    const textDecoder = new TextDecoder(record.encoding);
                    setMessage(textDecoder.decode(record.data));
                    break;
                case "url":
                    // TODO: Read URL record with record data.
                    break;
                default:
                    // TODO: Handle other records with record data.
            }
        }

        setSerialNumber(serialNumber);

        //alert("cid :" + serialNumber);
        cookies.set('cid', serialNumber);

        setRound({
            ...round,
            checkpoint : serialNumber
        });

        addCheckpointToRound();

        setActions({
            status: 'scanned'            
        });
    };

    useEffect(() => {
        scan();
    }, [scan]);

    return(
        <>
            {scanError && <Error title='Scan error!' description={scanError} />}
            {actions.status === 'scanned' ?  
            <div>
                <p>Serial Number: {serialNumber}</p>
                <p>Message: {message}</p>
            </div>
            : (round?.id ? <Scanner status={actions.status} /> : <div>Round not started. Start or continue one.</div> )
            }
        </>
    );
};

export default Scan;
