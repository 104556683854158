import React from 'react';
import { Route, Routes } from 'react-router-dom';
import MainContainer from "./components/MainContainer";
import NotFound from './pages/NotFound';
import routes from './config/routes.js';

function App() {
  return (
      <Routes>
        <Route path='/' element={<MainContainer />}>
          {routes.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={route.component}
            />
          ))}
          <Route path="*" element={<NotFound />}/>        
        </Route>        
      </Routes>
  );
}
 
export default App;
