import { useNavigate } from "react-router";
import { Flex, Button } from "@chakra-ui/react";
import { TbDoorEnter } from 'react-icons/tb';

function NotFound() {
	const navigate = useNavigate();

	return (
		<Flex
            flexDirection="column"
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
			gap="16px"
        >
            <h1>Page not found</h1>

			<Button 
                leftIcon={<TbDoorEnter />} 
                w="100%" 
                size="lg" 
                colorScheme="teal"
                onClick={() => navigate("/")}
            >
                Go to Dashboard
            </Button>
        </Flex>
	);
}

export default NotFound;